import kaboom from "kaboom";
import tiledKaboom from "~/utils/tiled";
import spacedSprite from "~/plugins/spaced";

const k = kaboom({
  clearColor: [0, 0, 0],
  crisp: false,
  scale: 1,
  texFilter: "linear",
  plugins: [
    spacedSprite,
    tiledKaboom,
  ],
});

export default k;
