import k from "~/kaboom";

const LobbyScene = () => {
  k.add([
    k.text("Egg and Bacon", {
      size: 64,
    }),
    k.pos(k.width() / 2, k.height() / 2),
    k.origin("center"),
  ]);

  k.keyPress("space", () => go("battle"));
  k.mouseClick(() => go("battle"));
};

export default LobbyScene;
