import k from "./kaboom";
import lobby from "./scenes/lobby";
import battle from "./scenes/battle";

// Preload assets
k.loadSpriteAtlas("sprites/animals.png", "sprites/animals.json");
k.loadSprite("egg", "sprites/egg.png");
k.loadSprite("bacon", "sprites/bacon.png");

// Setup scenes
k.scene("lobby", lobby);
k.scene("battle", battle);

// Start
k.go("lobby");
