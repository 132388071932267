import k from "~/kaboom";

const within = (v, min, max, delta) => {
  // If the whole world fits into the viewport, center it.
  if (min + 2 * delta > max) {
    return (min + max) * 0.5;
  }
  if (v - delta < min) {
    return min + delta;
  }
  if (v + delta > max) {
    return max - delta;
  }
  return v;
}

function camFollow(world) {
  return {
    id: "camFollow",
    require: ["pos", "sprite"],

    update() {
      const width = this.width * this.scale.x * 0.5;
      const height = this.width * this.scale.y * 0.5;
      this.pos.x = Math.max(world.min.x + width, this.pos.x);
      this.pos.x = Math.min(world.max.x - width, this.pos.x)
      this.pos.y = Math.max(world.min.y + height, this.pos.y);
      this.pos.y = Math.min(world.max.y - width, this.pos.y)

      const scale = 0.5 / world.scale;
      const x = within(this.pos.x, world.min.x, world.max.x, k.width() * scale);
      const y = within(this.pos.y, world.min.y, world.max.y, k.height() * scale);
      k.camPos(k.vec2(x, y));
    },
  };
}

export default camFollow;
