import k from "~/kaboom";
import spawnPlayer from "~/actors/player";
import loadGrassyLevel from "~/levels/grassy";
import loadPixelsLevel from "~/levels/pixels";

const BattleScene = async () => {

  //const world = await loadGrassyLevel();
  const world = await loadPixelsLevel();

  k.camScale(k.vec2(world.scale, world.scale));

  k.add([
    k.text("Fight!", {
      size: 64,
    }),
    k.pos(k.center()),
    k.origin("center"),
    k.fixed(),
    k.z(1000),
    k.lifespan(2),
  ]);

  const team = k.choose(["chicken", "pig"]);
  const player = spawnPlayer(team, world);

  k.collides("bullet", "obstacle", (bullet, obstacle) => {
    k.addKaboom(bullet.pos);
    k.destroy(bullet);
  });
};

export default BattleScene;
