export default (k) => ({
  async loadSpacedSprite(name, src, conf) {
    const { spacing = 0, sliceX = 1, sliceY = 1, ...cfg } = conf;
    const sprite = await k.loadSprite(name, src, cfg);
    sprite.frames = slice(spacing, sliceX, sliceY, sprite.tex.width, sprite.tex.height);
    return sprite;
  },
});

function slice(space, x, y, w, h) {
  const frames = [];
  const sw = space / w;
  const sh = space / h;
  const qw = (w - (x - 1) * space) / (x * w);
  const qh = (h - (y - 1) * space) / (y * h);


  for (let j = 0; j < y; j++) {
    for (let i = 0; i < x; i++) {
      frames.push(quad(
        i * (qw + sw),
        j * (qh + sh),
        qw,
        qh,
      ));
    }
  }
  return frames;
}
