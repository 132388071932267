import k from "~/kaboom";

const SPEED = 1600;

const spawnBullet = (type, pos, angle, scale) => {
  const bullet = k.add([
    k.sprite(type),
    k.pos(pos),
    k.rotate(angle),
    k.origin("center"),
    k.move(angle - 90, SPEED / scale),
    k.area(),
    k.scale(0.15 / scale),
    "bullet",
    type,
  ]);

  return bullet;
};

export default spawnBullet;
