import k from "~/kaboom";
import map from "./grassy.json";

const loadLevel = async () => {
  const { sprites, levels, objects, key } = await k.loadTiledMap(map, "sprites/");
  const levelz = levels.map(level => k.addLevel(level, { width: 64, height: 64, ...key }));
  const characters = objects.map(k.add);
  const min = levelz[0].getPos();
  const max = k.vec2(
    min.x + levelz[0].width(),
    min.y + levelz[0].height(),
  );
  return {
    min, max,
  };
};

export default loadLevel;
