import pako from "pako";

/* global atob */
// big array of characters that are suitable for maps
// TODO: I got annoyed after 3001
const mapsymbols = [...new Array(4052)].reduce((a, v, c) => {
  if ([
    92,
    173,
    847,
    888,
    889,
    907,
    909,
    930,
    1328,
    1367,
    1368,
    1376,
    1416,
    1419,
    1420,
    1421,
    1424,
    1565,
    1867,
    1868,
    2081,
    2082,
    2094,
    2095,
    2111,
    2140,
    2141,
    2445,
    2446,
    2449,
    2450,
    2473,
    2481,
    2490,
    2491,
    2501,
    2502,
    2505,
    2506,
    2526,
    2532,
    2533,
    2564,
    2577,
    2578,
    2601,
    2609,
    2612,
    2615,
    2618,
    2619,
    2621,
    2633,
    2634,
    2638,
    2639,
    2640,
    2653,
    2692,
    2702,
    2706,
    2729,
    2737,
    2740,
    2746,
    2747,
    2758,
    2762,
    2766,
    2767,
    2788,
    2789,
    2820,
    2829,
    2830,
    2833,
    2834,
    2857,
    2865,
    2868,
    2874,
    2875,
    2885,
    2886,
    2889,
    2890,
    2910,
    2916,
    2917,
    2948,
    2955,
    2956,
    2957,
    2961,
    2966,
    2967,
    2968,
    2971,
    2973,
    2976,
    2977,
    2978,
    2981,
    2982,
    2983,
    2987,
    2988,
    2989

  ].includes(c)) {
    return a
  }

  if (c < 33) {
    return a
  }

  const ranges = [
    [126, 161],
    [895, 900],
    [1479, 1488],
    [1514, 1520],
    [1524, 1536],
    [1969, 1984],
    [2042, 2048],
    [2142, 2207],
    [2238, 2260],
    [2384, 2389],
    [2482, 2486],
    [2510, 2519],
    [2519, 2524],
    [2555, 2561],
    [2570, 2575],
    [2626, 2631],
    [2641, 2649],
    [2654, 2662],
    [2677, 2689],
    [2768, 2784],
    [2801, 2809],
    [2809, 2817],
    [2893, 2902],
    [2903, 2908],
    [2935, 2946]
  ]

  for (const r of ranges) {
    if (c > r[0] && c < r[1]) {
      return a
    }
  }

  return [...a, String.fromCharCode(c)]
}, [])

function verifyTiledMap(mapObj) {
  // TODO: add more validation here
  if (!mapObj) {
    throw new Error('Map parameter is required.');
  }

  if (mapObj.orientation !== 'orthogonal') {
    throw new Error(`Map is ${mapObj.orientation}. Only orthogonal is currently supported.`);
  }
  if (mapObj.renderorder !== 'right-down') {
    throw new Error(`Map is ${mapObj.renderorder}. Only right-down is currently supported.`);
  }
}

// decode data in Tiled base64 format
function base64Decode(data) {
  const len = data.length;
  const bytes = new Array(len / 4);

  // Interpret data as an array of bytes representing little-endian encoded uint32 values.
  for (let i = 0; i < len; i += 4) {
    bytes[i / 4] = (
      data.charCodeAt(i) |
      data.charCodeAt(i + 1) << 8 |
      data.charCodeAt(i + 2) << 16 |
      data.charCodeAt(i + 3) << 24
    ) >>> 0;
  }

  return bytes;
}


function loadTileLayer(layer, mapsymbols) {
  const { width, height } = layer;

  if (layer.encoding === 'base64') {
    if (!layer.compression || layer.compression === '') {
      layer.data = base64Decode(atob(layer.data));
    } else {
      const d = Uint8Array.from(atob(layer.data).split('').map(c => c.charCodeAt(0)))
      layer.data = base64Decode(String.fromCharCode(...pako.inflate(d)));
    }
  }

  const mapArray = [...new Array(height)].map(() => (new Array(width + 1).fill(' ')));
  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      const cell = (y * width) + x
      if (layer.data[cell] !== 0) {
        mapArray[y][x] = mapsymbols[layer.data[cell]];
      }
    }
  }
  return mapArray.map(l => l.join(''));
}

function loadObjectGroup(k, layer, createTile) {
  if (layer.draworder !== "topdown") {
    throw new Error(`Layer ${layer.name} has ${layer.draworder} draw order. Only topdown is currently supported.`);
  }

  return layer.objects.filter(obj => obj.visible).map(obj => ([
    ...createTile(obj.gid),
    k.pos(obj.x, obj.y),
    k.origin("botleft"),
  ]));
}

const getProperty = (props = [], name, type) =>
  props.find(p => p.name === name && p.type === type);

const propToComponent = k => ({ name, type, value }) => {
  if (name === "solid" && type == "bool" && value) {
    return [
      k.area(),
      k.solid(),
      "obstacle",
    ];
  }
  return [];
};


const addComponents = (k, tileset, gid) => {
  const tiles = tileset.tiles || [];
  const tile = tiles.find(t => t.id === gid);
  if (tile) {
    return tile.properties
      .map(propToComponent(k))
      .flat();
  }
  return [];
};

export default (k) => ({
  verifyTiledMap,

  async loadTiledMap(mapObj, location = "") {
    if (typeof mapObj === 'string') {
      mapObj = await fetch(mapObj).then(r => r.json());
    }

    verifyTiledMap(mapObj);

    const map = {
      sprites: [],
      levels: [],
      objects: [],
      key: {},
    };

    for (const tileset of mapObj.tilesets) {
      if (tileset.margin !== 0) {
        console.error(`${tileset.name} uses margins. That is currently unsupported. Skipping.`);
        continue;
      }

      const spacing = tileset.spacing;
      const sliceX = (tileset.imagewidth + spacing) / (tileset.tilewidth + spacing);
      const sliceY = (tileset.imageheight + spacing) / (tileset.tileheight + spacing);
      const filter = getProperty(mapObj.properties, "filter", "string");

      map.sprites.push(await k.loadSpacedSprite(tileset.name, `${location}${tileset.image}`, { sliceX, sliceY, spacing, filter }));
      for (let frame = tileset.firstgid; frame < (tileset.firstgid + tileset.tilecount); frame++) {
        const tile = [
          k.sprite(tileset.name, { frame: frame - 1 }),
          ...addComponents(k, tileset, frame),
        ];
        map.key[mapsymbols[frame]] = () => [...tile];
      }
    }

    const createTile = frame => map.key[mapsymbols[frame]]();

    for (const layer of mapObj.layers) {
      if (layer.visible) {
        if (layer.type === "tilelayer") {
          map.levels.push(loadTileLayer(layer, mapsymbols));
        } else if (layer.type === "objectgroup") {
          map.objects = map.objects.concat(loadObjectGroup(k, layer, createTile));
        }
      }
    }

    map.mapObj = mapObj;

    return map
  }
});
