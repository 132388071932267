import k from "~/kaboom";
import { throttle } from "~/utils";
import camFollow from "~/components/camFollow";
import spawnBullet from "./bullet";


const SPEED = 500;

const ammunitionMap = {
  chicken: "egg",
  pig: "bacon",
};

const spawnPlayer = (team, world) => {
  const player = k.add([
    k.sprite(team),
    k.pos(50, 50),
    k.rotate(0),
    k.origin("center"),
    k.area(),
    k.solid(),
    k.health(100),
    k.scale(0.8 / world.scale),
    camFollow(world),
  ]);

  player.action(() => {
    player.angle = player.pos.angle(k.mouseWorldPos()) - 90;
  });

  const speed = SPEED / world.scale;

  k.keyDown(["w", "up"], () => player.move(0, -speed));
  k.keyDown(["a", "left"], () => player.move(-speed, 0));
  k.keyDown(["s", "down"], () => player.move(0, speed));
  k.keyDown(["d", "right"], () => player.move(speed, 0));

  const fire = throttle(0.5, () => spawnBullet(ammunitionMap[team], player.pos, player.angle, world.scale));
  k.keyRelease("space", fire);
  k.mouseClick(fire);

  return player;
};

export default spawnPlayer;
