import k from "../kaboom";

export const throttle = (delta, func) => {
  let lastTime = 0;
  return function () {
    const t = k.time();
    if ((t - lastTime) >= delta) {
      lastTime = t;
      func(...arguments);
    }
  };
};
